import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { loginUser, selectIsUserLogged } from '../redux/authSlice';
import { setError, resetError } from '../redux/errorSlice';
import { UserLoginData } from '../types/auth';
import GoForwardIcon from '../static/icons/go-forward.svg';

const LoginScreen = () => {
  const isUserLogged = useAppSelector(selectIsUserLogged);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm<UserLoginData>();

  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (isUserLogged) {
      navigate('/');
    }
  }, [navigate, isUserLogged]);

  useEffect(() => {
    if (checked) {
      dispatch(resetError());
    }
  }, [dispatch, checked]);

  const submitForm = async (data: UserLoginData) => {
    if (checked) {
      dispatch(resetError());
      await dispatch(loginUser(data));
    } else {
      dispatch(setError(t('login_terms_error')));
    }
  };

  return (
    <div className="login-screen">
      <div className="login-form">
        <p className="header">{t('login_header')}</p>
        <p className="label">{t('login_label')}</p>
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="form-group">
            <label>{t('login_email')}</label>
            <input type="email" className="form-input" {...register('email')} />
            <label>{t('login_password')}</label>
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-input"
              {...register('password')}
            />
          </div>
          <div className="login-statute-row">
            <input type="checkbox" onChange={() => setChecked(!checked)} />{' '}
            <p>
              {t('login_accept')}{' '}
              <a
                href="https://movello.org/polityka-prywatnosci/"
                target="_blank"
                rel="noreferrer"
                className="statute-link"
              >
                {t('login_terms')}
              </a>
            </p>
          </div>
          <div className="login-form-row">
            <Link to={'/forgot-password'} className="reset-password-link">
              {t('login_reset_password')}
            </Link>
            <button type="submit" className="button-submit-login">
              {t('login_login')}
              <img
                src={GoForwardIcon}
                alt="go-forward-icon"
                className="go-forward-icon"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
