import React, { useEffect } from 'react';
import AppContainer from './components/AppContainer';
import './css/App.css';

function App() {
  useEffect(() => {
    if (window.location.hostname === 'panel.movello.pl') {
      const newHost = 'panel.movello.org';
      const newUrl = `https://${newHost}${window.location.pathname}${window.location.search}${window.location.hash}`;
      window.location.replace(newUrl);
    }
  }, []);

  return (
    <div className="App">
      <AppContainer />
    </div>
  );
}

export default App;
